.App {
  text-align: center;
  width: 100%;
}

.AppLogo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFFFFF;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 380px;
  border-radius: 10px;
  padding: 25px;
  margin: 0 auto;
}

.App-link {
  color: #ffffff;
  font-size: calc(1px + 2vmin);
  cursor: pointer;
  background-color: #f36f21;
  width: 120px;
  height: 45px;
  border-radius: 50px;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homeLinks {
/*	margin-top: 25%;*/
	width: 100%;
	display: block;
/*	padding: 5% 10%;*/
}

.floatLeft {
	float: left;
}
.floatRight {
	float: right;
}

body .VersionInfo,
body .Copyright {
  color: #588bca;
  font-weight: bold;
  font-size: 12px !important;
}
.Copyright {
	text-align: right;
	margin-left: auto !important;
	font-size: 12px !important;
}
.FooterBar {
	background-color: #F3FFFF;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.css-2ulfj5-MuiTypography-root {
	line-height: 5 !important;
}

.error-page-height{
 height: calc(100vh - 20px) !important;
}

.error-details-container{
  margin: auto;
  width: 30%;    
  padding: 10px;
  height: 50%;
  text-align: center;
}

.dFlexJustifyCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AppTxt {
	color: #000;
	font-size: calc(6px + 2vmin);
}

.lineHeight {
	line-height: 0.2;
}

.swpDesc {
	margin: 50px 0px;
	background: linear-gradient(lavender, aliceblue, #fbfbfb, #fcfcfc);
	font-size: calc(4px + 2vmin);
	color: darkslateblue;
}

.swpOrnge {
	color: #f36f21;
}
.fontWytBold {
	font-weight: bold;
}

.quesTxt {
	margin-bottom: 20px;
	font-size: calc(1px + 1.5vmin);
	line-height: 0.1;
	color: darkslateblue;
}

.contactLink {
	margin-top: 15px;
	font-size: calc(1px + 1.5vmin);
	color: #f36f21;
	font-weight: bold;
}

.swpLogo{
	margin-top: 30px;
}

.ebayLogo {
	margin-top: 40px;
	margin-left: 100px;
}

.eBayHeader {
  background-color: #FFFFFF;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-width: 1000px;
  border-radius: 10px;
  padding: 25px;
  margin: 0 auto;
}

.ebayConnector {
	margin-top: 10px;
	margin-left: 100px;
	width: 250px;
}

.carrierLogo {
	margin-left: 100px;
}

@media (max-width: 600px) {
	.swpLogo{
		margin-left: 100px;
	}
	.ebayConnector {
		margin-top: 50px;
		margin-left: 50px;
	}
}