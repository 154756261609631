@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
 /* background: var(--mui-primary);
   	background: linear-gradient(darkslateblue, lavender, aliceblue, lavender, darkslateblue);
  */
	background: linear-gradient(aliceblue, lavender,  #9bc9f28c);
}

:root {
  --backgroundcolor: var(--mui-palette-backgroundcolor);
  --colorWhite: var(--mui-palette-colorWhite);
  --colorBlue: var(--mui-palette-colorBlue);
  --colorRed: var(--mui-palette-error-main);
  --paperBackground: var(--mui-palette-background-paper);
  /* --mui-palette-primary-main: #1976d2; */
  /* --mui-palette-primary-backgroundcolor: #eaecf8; */
  --mui-primary: var(--mui-palette-primary-main);
  /* --mui-primary: #16314c; */
  --mui-secondary: var(--mui-palette-secondary-main);
}

.rootContainer {
  width: calc(100% - 70px);
  /* height: 100vh; */
}

.rootContainerExpand {
  width: calc(100% - 270px);
}

/*  */
.AppBar {
  background-color: var(--colorWhite) !important;
}
.AppBarIcons {
  width: 20px;
  max-width: 25px;
  margin-right: 10px;
  cursor: pointer;
}
.AppBarUserIcon {
  width: 15px;
  max-width: 25px;
  height: 17px;
}
.AppBarLiveIcon {
  max-width: 25px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  max-height: 25px;
}
.logo {
  max-width: 140px;
  margin: 10px 20px;
}

.headerMenu .MuiPaper-root {
  background-color: #16314c !important;
  color: var(--colorWhite) !important;
  border-radius: 0px !important;
  top: 45px !important;
}
.headerMenu .MuiPaper-root ul.MuiList-root {
  border: none;
}

.headerMenu .MuiPaper-root ul li {
  font-size: 12px;
  border-bottom: solid 1px #345678;
}
.headerMenu .MuiPaper-root ul li:hover {
  color: #dbdbdb;
}
.headerMenu .MuiPaper-root ul li:last-child {
  border-bottom: none;
}
header.MuiPaper-root img:hover {
  opacity: 0.5;
}
header .MuiToolbar-root span.MuiButton-startIcon {
  background: var(--mui-primary);
  display: flex !important;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.registerContainer {
  height: 100vh;
}

.registerContainer .registerInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  /* border-top-right-radius: 10px; */
  /* border-bottom-left-radius: 10px; */
}

.registerInputContainer img {
  width: 50%;
}

.registerInputContainer .userDetailsContainer {
  text-align: center;
}

.terms_checkbox_alignment {
  margin: 0 auto;
  display: flex;
}
.terms_checkbox {
  align-items: start !important;
  padding: 1px !important;
  height: fit-content !important;
}
.terms_checkbox_alignment .linkcolor {
  color: dodgerblue;
  text-decoration-color: dodgerblue;
}
.terms_checkbox_alignment .text_alignment {
  text-align: left;
  margin-left: 5px;
}

/*  */
.sidebarIcon {
  position: absolute !important;
  right: -15px;
  top: 15px;
  z-index: 1;
}
body .sidebarIcon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #f49044;
  margin: 12px 0 0 0 !important;
  padding: 5px;
  cursor: pointer;
}
body .sidebarIcon:hover {
  background-color: #f49044;
}
body .sidebarIcon svg {
  fill: #fff;
}
.sidebar.expanded .left-listing .MuiButtonBase-root {
  justify-content: center;
}
/* dashboard style start */
* {
  box-sizing: border-box;
}
body {
  box-sizing: border-box;
}

.left-menu .left-listing > .MuiButtonBase-root {
  /* background-color: #578cc9; */
  /* border-radius: 20px; */
  margin: 0px 0;
  box-sizing: border-box;
  width: auto;
  display: flex;
  align-items: center;
}
.left-menu .left-listing > .MuiButtonBase-root:hover {
  /* background-color: #f49044; */
  color: #fff;
}
.sidebar.expanded .left-menu .left-listing > .MuiButtonBase-root svg {
  fill: #8a9891;
  font-size: 20px;
}

.sidebar.expanded .left-menu .left-listing > .MuiButtonBase-root:hover svg,
.sidebar.expanded .left-menu .left-listing > .MuiButtonBase-root.active svg {
  fill: #fff;
}

.left-menu .left-listing > .MuiButtonBase-root {
  color: #8a98a5;
  font-size: 18px;
}
.left-menu .left-listing > .MuiButtonBase-root span {
  font-size: 16px !important;
}

.left-menu .left-listing .MuiCollapse-root .MuiList-root {
  margin-left: 30px;
}
.left-menu .MuiCollapse-root span {
  color: #8a98a5;
  font-size: 16px;
  position: relative;
  font-weight: 400;
}
/* .left-menu .MuiCollapse-root span:after {
  content: "";
  background: #578cc9;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  position: absolute;
  left: -14px;
  top: 6px;
  overflow: hidden;
} */
.left-menu .MuiCollapse-root span:hover {
  color: #fff;
  font-size: 16px;
}
.left-menu .MuiCollapse-root span:after:hover {
  background: #f49044;
  font-size: 16px;
}

.left-menu .left-listing .MuiButtonBase-root .MuiListItemIcon-root {
  min-width: 30px !important;
  position: relative;
  top: -1px;
}
.sidebar.expanded .left-menu .MuiListItemIcon-root {
  display: flex;
}
.sidebar .left-menu .MuiListItemIcon-root > svg {
  font-size: 25px;
}
.sidebar .left-menu .MuiListItemIcon-root {
  min-width: inherit;
  display: block;
  padding: 0 !important;
  margin: 0 auto !important;
}
body .MuiModal-root h6.MuiTypography-root {
  font-size: 16px !important;
  font-weight: 500;
}
div .MuiTypography-root {
  font-weight: bold;
  /* margin-left: 20px; */
}

.appDisplay {
  display: flex;
}

.txtCenterAlign {
  text-align: center !important;
}

.expanded {
  width: 270px !important;
  align-items: flex-start;
}

.sidebar {
  min-height: 100%;
  width: 70px;
  background-color: #16314c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s width;
  position: relative;
  -moz-box-shadow: 5px 0 5px -3px #888;
  -webkit-box-shadow: 5px 0 5px -3px #888;
  box-shadow: 5px 0 5px -3px #888;
}

.sidebarBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: inherit;
  margin-bottom: 10%;
}

.sidebar.expanded .left-listing .MuiButtonBase-root {
  justify-content: center;
}

@media (min-width: 600px) {
  body header > div.MuiToolbar-root .sidebar header {
    min-height: 80px;
    border-bottom: solid 1px #c9ccd4;
  }
  .sidebar header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.active {
  color: #fff !important;
  /* background-color: var(--mui-secondary) !important; */
}
.activeSubList > div > span {
  color: #fff !important;
  /* var(--mui-secondary) !important; */
}
.activeIconOnly {
  padding: 6px 16px !important;
}
.activeIconOnly > div > svg {
  color: #fff !important;
  /* var(--mui-secondary) !important; */
}
.iconOnlyPadding {
  padding: 6px 16px !important;
}
.iconOnlyPadding > div > svg {
  color: #8a98a5 !important;
}

.heightCls {
  height: 88.8vh !important;
}
.mainDiv {
  padding: 20px 20px 20px;
  background: #fff;
  margin: 10px !important;
  border-radius: 15px;
  min-height: calc(98.6vh - 91px);
  overflow: auto;
}

/* width */
.width10p {
  width: 10% !important;
}
.width15p {
  width: 15% !important;
}
.width20p {
  width: 20% !important;
}
.width25p {
  width: 25% !important;
}
.width30p {
  width: 30% !important;
}
.width35p {
  width: 35% !important;
}
.width40p {
  width: 40% !important;
}
.width45p {
  width: 45% !important;
}
.width50p {
  width: 50% !important;
}
.width55p {
  width: 55% !important;
}
.width60 {
  width: 60% !important;
}
.width65p {
  width: 65% !important;
}
.width70 {
  width: 70% !important;
}
.width75p {
  width: 75% !important;
}
.width80 {
  width: 80% !important;
}
.width100 {
  width: 100% !important;
}
.width90 {
  width: 90% !important;
}
.width95 {
  width: 95% !important;
}
.width99 {
  width: 99% !important;
}
.width10px {
  width: 10px !important;
}
.width35px {
  width: 35px !important;
}
.width45px {
  width: 45px !important;
}
.width60px {
  width: 60px !important;
}
.width70px {
  width: 70px !important;
}
.width80px {
  width: 80px !important;
}
.width90px {
  width: 90px !important;
}
.width100px {
  width: 100px !important;
}
.width150px {
  width: 150px !important;
}

label.width100px {
  width: 160px !important;
  margin-left: 10px;
  display: inline-block;
}

/* height */
.height15v {
  height: 15vh !important;
}
.height35 {
  height: 35vh !important;
}
.height50 {
  height: 50vh !important;
}
.height60 {
  height: 60vh !important;
}
.height75v {
  height: 75vh !important;
}
.height80v {
  height: 80vh !important;
}
.height80p{
  height: 80%;
}
.height90v {
  height: 90vh !important;
}
.height90p {
  height: 90% !important;
}
.height100v {
  height: 100vh !important;
}
.height100p {
  height: 100% !important;
}
.maxHeight100p {
  max-height: 100% !important;
}

/* padding */
.padding0 {
  padding: 0px !important;
}
.padding5 {
  padding: 5px !important;
}
.padding10 {
  padding: 10px !important;
}
.padding20 {
  padding: 20px !important;
}
.padding40 {
  padding: 40px !important;
}
.paddingLeft0 {
  padding-left: 0px !important;
}
.paddingLeft5p {
  padding-left: 5% !important;
}
.paddingLeft5 {
  padding-left: 5px !important;
}
.paddingLeft10 {
  padding-left: 10px !important;
}
.paddingLeft40 {
  padding-left: 40px !important;
}

.paddingRight10 {
  padding-right: 10px !important;
}

.paddingTop10 {
  padding-top: 10px !important;
}
.paddingTop300 {
  padding-top: 300px !important;
}

.paddingTop0 {
  padding-top: 0px !important;
}
.paddingTop20 {
  padding-top: 20px !important;
}
.paddingBottom0 {
  padding-bottom: 0px !important;
}
.paddingBottom10 {
  padding-bottom: 10px !important;
}

/* margin */
.margin5 {
  margin: 5px !important;
}
.margin10 {
  margin: 10px !important;
}

.marginLeft5p {
  margin-left: 5% !important;
}
.marginLeft5 {
  margin-left: 5px !important;
}
.marginLeft10 {
  margin-left: 10px !important;
}
.marginLeft15 {
  margin-left: 15px !important;
}
.marginLeft20 {
  margin-left: 20px !important;
}
.marginLeft25 {
  margin-left: 25px !important;
}

.marginLeft100 {
  margin-left: 100px !important;
}

.marginBottom0 {
  margin-bottom: 0px !important;
}
.marginTop0 {
  margin-top: 0px !important;
}
.marginTop5 {
  margin-top: 5px !important;
}
.marginTop10 {
  margin-top: 10px !important;
}
.marginTop15 {
  margin-top: 15px !important;
}
.marginTop20 {
  margin-top: 20px !important;
}
.marginTop25 {
  margin-top: 25px !important;
}
.marginTop30 {
  margin-top: 30px !important;
}
.marginTop35 {
  margin-top: 35px !important;
}
.marginTop40 {
  margin-top: 40px !important;
}
.marginTop45 {
  margin-top: 45px !important;
}
.marginTop60 {
  margin-top: 60px !important;
}

.marginTop100 {
  margin-top: 100px !important;
}

.marginRight5 {
  margin-right: 5px !important;
}
.marginRight2p {
  margin-right: 2% !important;
}
.marginRight10 {
  margin-right: 10px !important;
}
.marginBottom5 {
  margin-bottom: 5px !important;
}
.marginBottom10 {
  margin-bottom: 10px !important;
}
.marginBottom20 {
  margin-bottom: 20px !important;
}
.marginBottom15 {
  margin-bottom: 15px !important;
}

.marginVertical10 {
  margin: 10px 0 !important;
}

.fontWeight {
  font-weight: 600 !important;
}
.fontWeight500 {
  font-weight: 500 !important;
}
.loader {
  color: var(--colorWhite);
  z-index: 9;
}

.redError {
  color: var(--colorRed) !important;
}

body .VersionInfo,
body .Copyright {
  color: #588bca;
  font-weight: bold;
  font-size: 12px !important;
}
.Copyright {
  text-align: right;
  margin-left: auto !important;
  font-size: 12px !important;
}
.FooterBar {
  background-color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cursorPointer {
  cursor: pointer;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.left {
  flex: 1;
}

.left .pageTitle h5:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: var(--mui-secondary);
  height: 2px;
  width: 50%;
}

.left .pageTitle h5 {
  width: max-content;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
}
.right {
  display: flex;
  gap: 10px;
}

.centerAlignment {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnFontSize {
  font-size: 15px !important;
  text-transform: unset !important;
}

.btnFontSize12px {
  font-size: 12px !important;
  text-transform: unset !important;
}
.gridMargin {
  margin: 0px !important;
  width: 100% !important;
}

.usersTable .entriesView {
  margin-top: 0;
}

.inlineLabel {
  display: flex;
  flex-direction: row; /* justify-content: center; */
  align-items: center;
}

.inlineLabel input::-webkit-input-placeholder {
  /* Edge */
  line-height: 30px !important;
}
.inlineLabel input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  line-height: 30px !important;
}
.inlineLabel input::placeholder {
  line-height: 30px !important;
}

.entriesView {
  margin-left: auto;
  /* justify-content: center; */
  text-align: center;
  align-items: center;
  margin-top: 15px;
}

.selectDdPadding > div {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.floatRight {
  float: right !important;
}

.txtBorder .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #ccc !important;
}
.txtBorder .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ddd !important;
}
.txtBorder .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline:hover {
  border-color: #ddd !important;
}

.txtBorder .MuiOutlinedInput-root {
  padding-right: 0px !important;
  border-radius: 0;
}
.paddingLeft0 .MuiOutlinedInput-root {
  padding-left: 0px !important;
}
.txtBorder .MuiInputAdornment-root {
  margin-left: 0;
}
/* .txtBorder .MuiIconButton-sizeMedium {
  border-radius: 0;
  border-left: 1px solid;
} */
.tableBorder td.MuiTableCell-root {
  border-bottom: 1px solid #e2efff;
  padding: 7px 10px;
  text-align: left;
}

.storeActions {
  margin-left: 10px;
  width: fit-content !important;
  cursor: pointer;
}

.paginationAlignment {
  display: flex;
  justify-content: center;
  padding: 10px;
}

table thead th {
  background-color: #e2efff;
  padding: 10px 10px 6px !important;
  color: #000;
  text-transform: uppercase;
}

table tbody tr {
  color: #000;
  font-size: 14px;
  padding: 10px 10px 6px !important;
  line-height: 1;
  font-weight: 400;
}
table tbody tr:nth-child(even) {
  background-color: #f9fcff;
}
table thead th.MuiTableCell-root {
  /* color: #ffffff; */
  font-size: 14px;
  font-weight: 700;
  /* text-transform: uppercase; */
  /* padding: 10px !important; */
  text-align: left;
  letter-spacing: 1px;
  line-height: 1;
}

/* input.MuiInputBase-input {
  height: 25px !important;
  padding: 5px 10px !important;
  line-height: 1 !important;
  font-size: 12px !important;
} */

.selectDropDown {
  max-height: 200px !important;
  max-width: 250px !important;
}

@media (min-width: 600px) {
  body header > div.MuiToolbar-root,
  .sidebar header {
    min-height: 45px !important;
  }
  .sidebar header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  /* Styles for screens between 600px and 959px wide */
  .txtOverFlow {
    width: 150px !important;
  }
}

@media (min-width: 960px) {
  /* Styles for screens 960px and wider */
  .txtOverFlow {
    width: 150px !important;
  }
}

@media (min-width: 1200px) {
  /* Styles for screens 1200px and wider */
}

input.MuiInputBase-input {
  /* box-sizing: border-box !important; */
  width: 95%;
  font-size: 14px;
  /*padding: 5px 10px;
  line-height: 1; */
}
textarea.MuiInputBase-input,
.MuiSelect-select {
  font-size: 14px !important;
}

.dFlexCenter {
  display: flex;
  align-items: center;
}

.MuiFormControl-root .MuiFormLabel-root {
  font-size: 14px;
  color: #000;
}
.MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-shrink {
  font-size: 16px;
  color: #000;
}
.borderBox .MuiPaper-root {
  box-shadow: inherit;
  border-radius: inherit;
}
.borderBox .MuiTableContainer-root {
  border: solid 1px #e2efff;
  box-shadow: 0 0 10px #e2efff;
}
table thead th.MuiTableCell-root.MuiTableCell-alignCenter {
  text-align: center;
}
.usersTable .entriesView .MuiInputBase-root {
  border-radius: 0;
}
.MuiPagination-ul button.Mui-selected {
  color: #fff;
  border: inherit;
  background-color: #16314c;
}
.searchBar input.MuiInputBase-input {
  padding: 8px 10px;
  line-height: 1;
}
.usersTable .entriesView .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  border-color: #ddd;
}
.tableBorder svg.MuiSvgIcon-root {
  font-size: 20px;
}
.store-main .MuiGrid-container .MuiGrid-root:first-child {
  padding-left: 16px;
  padding-top: 0;
  margin-top: 20px;
}
.store-main .MuiGrid-container .MuiGrid-root {
  padding-left: 30px;
  margin-top: 20px;
  padding-top: 0;
}
.inputapikey .MuiFormControl-root {
  width: 100%;
  display: block;
  margin-top: 20px;
}
.inputapikey .MuiInputBase-root {
  width: 100%;
  border-radius: 0;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #ccc !important;
}

.popupModel {
  position: absolute;
  width: 30vw;
  background-color: var(--paperBackground);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  outline: none;
}
.popupModel > div > #modal-title {
  color: var(--colorWhite);
  background-color: var(--mui-primary);
  padding: 15px;
  border-radius: 10px 10px 0 0;
}
.modalStyle40 {
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
.modalStyle50 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}
.modalBtnAlignment {
  text-align: end;
  border-top: 1px solid #ccc;
  margin-top: 10px;
}
.modalcloseButton {
  position: absolute !important;
  right: 7px;
  top: 16px;
  color: var(--colorWhite) !important;
  padding: 0 !important;
}
input.MuiInputBase-input::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}
input.MuiInputBase-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}
input.MuiInputBase-input::placeholder {
  color: #000;
}
.selectDropDown ul li {
  color: #000;
  font-size: 14px;
}

.MuiFormControl-root .MuiInputBase-root {
  border-radius: 0;
  line-height: 2;
}
.MuiFormControl-root .MuiInputBase-multiline {
  padding: 14px;
  line-height: 1.4;
  min-height: 93px;
}

.dflex {
  display: flex;
}
.dBlock {
  display: block;
}
.txtOverFlow {
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.actionTableCell {
  display: flex;
  align-items: center;
}

/* CSS */

.iconColor {
  color: #666666 !important;
}
.label-border {
  border: solid 1px #16314c;
  border-radius: 4px;
  padding: 9px 16px;
  font-weight: 500;
}
.labelMargin {
  margin: 0 25px;
}

.MuiTabs-root .Mui-selected {
  color: var(--mui-palette-primary-main);
  background: #dde8f4;
}
.MuiTabs-root .MuiTabs-indicator {
  height: 0;
}

.MuiSelect-select,
.MuiInputBase-input {
  background-color: #fff !important;
}

.subHeader button {
  font-size: 10px;
}
.subHeader button svg {
  font-size: 13px !important;
}

.actionBtn {
  text-align: end !important;
}

.rightborder {
  border-right: 1px solid #ccc;
}

.switch input[type="checkbox"]:checked + .slider {
  /* background-color: green;   */
  background-color: #16314c;
}

.switch input[type="checkbox"]:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch input[type="checkbox"]:not(:checked) + .slider {
  /* background-color: red; */
  background-color: #16314c;
  opacity: 0.5;
}

.switch input[type="checkbox"]:not(:checked) + .slider:before {
  transform: translateX(4px);
}

.slider.round {
  display: flex;
  align-items: center;
}

.switch input[type="checkbox"]:checked + .slider .off {
  opacity: 0; /* Hide the CloseIcon when the switch is checked */
}
.switch input[type="checkbox"]:not(:checked) + .slider .on {
  opacity: 0; /* Hide the DoneIcon when the switch is not checked */
}

.fw-bold {
  font-weight: 700 !important;
}

table.tableBorder.versionInfo thead th {
  padding: 10px 22px !important;
}

.versioninfo-tabbody ul {
  margin: 0;
}
.versioninfo-tabbody ul li {
  list-style: none;
  padding: 10px 0;
  position: relative;
  list-style: circle;
  font-size: 14px;
}
/* .versioninfo-tabbody ul li:before {
  content: "";
  width: 5px;
  left: -16px;
  top: 50%;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  z-index: 10;
  transform: translateY(-50%);
} */
.versioninfo-tabbody ul li span {
  position: relative;
  z-index: 9;
}
.versioninfo-tabbody ul li:nth-child(odd) {
  position: relative;
}
.versioninfo-tabbody ul li::marker {
  background-color: #000;
  color: #000;
}
.over-hid {
  overflow: hidden !important;
}
.versioninfo-tabbody ul li:after {
  content: "";
  border-bottom: 1px solid #e2efff;
  height: 100%;
  width: 150%;
  position: absolute;
  top: 0;
  left: -10%;
}
.versioninfo-tabbody ul li:nth-child(even):after {
  content: "";
  width: 150%;
  left: -10%;
  right: 0;
  top: 0;
  height: 100%;
  background-color: rgb(226 239 255 / 20%);
  position: absolute;
  z-index: 1;
}
.tableBox .MuiPaper-root {
  box-shadow: inherit;
  border-radius: 0;
}
.versionInfo {
  border: 1px solid #e2efff;
  border-radius: 0;
  box-shadow: 0 0 10px rgb(22 49 76/5%);
}

.inputContainer {
  margin-bottom: 30px;
}
.loginInnerLeft {
  background: var(--mui-primary);
  width: 20vw;
}
.loginInnerLeft {
  display: flex;
  align-items: center;
}

.loginInnerLeft > div > img {
  width: 60%;
}
.loginInnerLeft > div > img:first-of-type {
  margin-top: 50px;
}
.loginInnerLeft > div > img:last-of-type {
  margin-top: 20px;
}

.loginInnerRight {
  background: #ffffff;
  width: 30vw;
}
.loginInnerRight > div {
  padding: 20px;
}
.loginInnerRight > div > #title > div {
  border-bottom: 2px solid var(--mui-secondary);
}
.loginInnerRight > div > #title > div {
  margin-bottom: 30px;
}
.loginInnerRight > div > #title > div > label {
  font-size: 30px;
  font-weight: 600;
  color: #16314c;
  line-height: 1.6;
}
.inputContainer > div > label {
  font-size: 18px;
  font-weight: 100;
  color: #16314c;
  line-height: 1.6;
  margin-bottom: 5px;
}

.inputContainer > div {
  margin-top: 20px;
}
.inputContainer > div > label {
  display: block;
  text-align: left;
}
.inputContainer > div > div {
  display: block;
}
.inputContainer > div > div > div {
  display: block;
}

.inputContainer label.MuiFormLabel-root {
  font-size: 12px;
  line-height: 1;
}
/* .inputContainer label.MuiFormLabel-root.MuiInputLabel-shrink {
  font-size: 1rem;
  line-height: 1.4375em;
} */

input.MuiInputBase-input {
  height: 22px !important;
  padding: 5px 10px !important;
  line-height: 1 !important;
  font-size: 14px !important;
}
.MuiSelect-select {
  min-height: 12px !important;
  height: 22px !important;
  padding: 5px 20px 5px 10px !important;
  line-height: 1.7 !important;
  font-size: 14px !important;
}

button.MuiButtonBase-root {
  padding: 2px 10px;
  font-weight: 400 !important;
}

table button.MuiButtonBase-root {
  padding: 5px;
  font-weight: 400 !important;
}

.entriesView .MuiSelect-select {
  line-height: 1.9 !important;
  padding-right: 28px !important;
  width: 25px;
}
.loginInnerContainer .MuiTextField-root label.MuiFormLabel-root {
  line-height: 15px !important;
  top: -8px;
}

.loginInnerContainer
  .MuiTextField-root
  label.MuiInputLabel-shrink.MuiFormLabel-root {
  font-size: 16px;
  top: 5px;
}

.registerInnerLeft {
  background: var(--backgroundcolor);
  width: 20vw;
}
.registerInnerLeft > div > img {
  width: 60%;
}
.registerInnerLeft > div > img:first-of-type {
  margin-top: 50px;
}
.registerInnerLeft > div > img:last-of-type {
  margin-top: 20px;
}

.registerInnerRight {
  background: #ffffff;
  width: 20vw;
}
.registerInnerRight > div {
  padding: 20px;
}
.registerInnerRight > div > #title {
  position: relative;
}
.registerInnerRight > div > #title > label {
  font-size: 30px;
  font-weight: 600;
  color: #16314c;
  line-height: 1.6;
}

.registerInputsContainer{
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.registerInputsContainer .login-redirection{
  margin-top: auto;
}

.userInfoOTP {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.otp-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded {
  border-radius: 5px !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.otp-form-control {
  display: block;
  width: 35px !important;
  height: 35px !important;
}
.infoAlignment {
  min-width: 220px;
  clear: left;
  float: left;
  text-align: right;
  padding-right: 5px;
}
.infoAlignment > span {
  padding-left: 5px;
  font-weight: bolder;
  padding-right: 5px;
}
.infoAlignment.align-left {
  width: 200px;
}
.updtProfileModal {
  position: absolute;
  width: 40vw;
  background-color: var(--paperBackground);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  outline: none;
}
.updtProfileModal > div > #modal-title {
  color: var(--colorWhite);
  background-color: var(--mui-primary);
  padding: 15px;
  border-radius: 10px 10px 0 0;
}
.profileInputContainer > div > label {
  font-size: 18px;
  font-weight: 100;
  color: #16314c;
  line-height: 1.6;
  margin-bottom: 5px;
}
/* .profileInputContainer > div {  margin-top: 20px;} */
.profileInputContainer > div > label {
  display: block;
  text-align: left;
}
.profileInputContainer > div > div {
  display: block;
}
.profileInputContainer > div > div > div {
  display: block;
}

.products-list-container{
  padding: 20px 10px !important;
}

.products-list-container .MuiCardHeader-action{
  margin: 0 !important;
  align-self: center;
}
.borderBottom {
  border-bottom: 1px solid #ccc;
}
.product-title-container{
  display: flex;
  align-items: center;
}

.billing-Opt-container{
  display: flex;
  align-items: baseline;
  margin-top: 30px;
}

.product-plans-container{
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  height: 100%;
}

.product-plans-container .product-plan-details{
  display: flex;
  flex-direction: column;
  height: 95%;
}

.product-plan-details .btnContainer {
  margin-top: auto; /* Make the button stick to the bottom */
  margin-bottom: 15px;
}

.fontsize18 {
  font-size: 18px !important;
}

.fontsize22 {
  font-size: 22px !important;
}

pre {
  white-space: pre-wrap; /* Preserve line breaks and wrap long lines */
  margin: 0; /* Remove default margins */
}

.minHeight70{
  min-height: 70px;
}

.product-plans-container ul {
  list-style-type: none;
  padding-left: 0;
}

.outerContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registerContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-page-height{
 height: calc(100vh - 20px) !important;
}

.error-details-container{
  margin: auto;
  width: 30%;    
  padding: 10px;
  height: 50%;
  text-align: center;
}

.dFlexJustifyCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

